// extracted by mini-css-extract-plugin
export var carouselContainer = "G_v2 d_w d_H d_bf d_Z";
export var carouselContainerCards = "G_v3 G_v2 d_w d_H d_bf d_Z";
export var carouselContainerSides = "G_v4 d_w d_H d_Z";
export var prevCarouselItem = "G_v5 d_w d_H d_0 d_k";
export var prevCarouselItemL = "G_v6 G_v5 d_w d_H d_0 d_k";
export var moveInFromLeft = "G_v7";
export var prevCarouselItemR = "G_v8 G_v5 d_w d_H d_0 d_k";
export var moveInFromRight = "G_v9";
export var selectedCarouselItem = "G_wb d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "G_wc G_wb d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "G_wd G_wb d_w d_H d_Z d_bf";
export var nextCarouselItem = "G_wf d_w d_H d_0 d_k";
export var nextCarouselItemL = "G_wg G_wf d_w d_H d_0 d_k";
export var nextCarouselItemR = "G_wh G_wf d_w d_H d_0 d_k";
export var arrowContainer = "G_wj d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "G_wk G_wj d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "G_wl G_wk G_wj d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "G_wm G_wj d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "G_wn";
export var nextArrowContainerHidden = "G_wp G_wm G_wj d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "G_kG d_0";
export var prevArrow = "G_wq G_kG d_0";
export var nextArrow = "G_wr G_kG d_0";
export var carouselIndicatorContainer = "G_ws d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "G_wt d_w d_bz d_bF";
export var carouselText = "G_wv d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "G_ww G_wv d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "G_wx d_b7";
export var carouselIndicator = "G_wy G_wx d_b7";
export var carouselIndicatorSelected = "G_wz G_wx d_b7";
export var arrowsContainerTopRight = "G_wB d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "G_wC d_0 d_bl d_bC";
export var arrowsContainerSides = "G_wD d_0 d_bl d_bC";
export var smallArrowsBase = "G_wF d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "G_wG G_wF d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "G_wH G_wG G_wF d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "G_wJ G_wF d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "G_wK G_wJ G_wF d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "G_n9";
export var iconSmall = "G_wL";
export var multipleWrapper = "G_wM d_bC d_bF d_bf";
export var multipleImage = "G_wN d_bC";
export var sidesPrevContainer = "G_wP G_wG G_wF d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "G_wQ G_wG G_wF d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";