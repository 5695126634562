// extracted by mini-css-extract-plugin
export var alignLeft = "B_rd d_fp d_bG d_dv";
export var alignCenter = "B_bP d_fq d_bD d_dw";
export var alignRight = "B_rf d_fr d_bH d_dx";
export var testimonialsContainer = "B_vk d_dW";
export var testimonialsContainerFull = "B_vl d_dT";
export var testimonialsMainHeader = "B_jw d_jw d_w d_cw";
export var testimonialsComponentParagraph = "B_jy d_jy";
export var testimonialsComponentSmall = "B_jx d_jx";
export var testimonialsComponentsWrapper = "B_js d_js d_y d_w d_cv d_b3 d_b5";
export var testimonialsComponentsWrapperDesign2 = "B_jt d_jt d_y d_w d_cv d_b3 d_b5 d_bC";
export var testimonialsComponentsWrapperDesign3 = "B_jv d_jv d_y d_w d_H d_b5 d_bT d_bz d_bJ d_bP d_bD";
export var testimonialsBackgroundColor = "B_vm d_d7";
export var colEqualHeight = "B_vn d_bz";
export var testimonialsColumnWrapper = "B_jz d_jz d_b3";
export var testimonialsImageWrapper = "B_jp d_jp d_w d_Z";
export var testimonialsImageWrapperDesign2 = "B_jq d_jq d_r d_Z d_bx";
export var testimonialsImageWrapperDesign3 = "B_jr d_jr d_Z d_bz";
export var design2TextWrapper = "B_vp d_bC d_bP d_bJ";
export var design3 = "B_vq d_cD d_H";
export var imageDesign2 = "B_vr";
export var SmallSmall = "B_tT z_tT z_tf z_tC";
export var SmallNormal = "B_tV z_tV z_tf z_tD";
export var SmallLarge = "B_tW z_tW z_tf z_tz";
export var exceptionWeight = "B_vs z_tH";