// extracted by mini-css-extract-plugin
export var customText = "s_r1 d_dv d_cs d_cg";
export var videoIframeStyle = "s_nS d_d5 d_w d_H d_by d_b1 d_R";
export var customImageWrapper = "s_rh d_cs d_cg d_Z";
export var customRow = "s_pb d_bD d_bf";
export var quoteWrapper = "s_gQ d_bC d_bP d_cs d_cg";
export var quoteBar = "s_nX d_H";
export var masonryImageWrapper = "s_n3";
export var title = "s_r2";
export var Title3Small = "s_r3 z_r3 z_tf z_tn";
export var Title3Normal = "s_r4 z_r4 z_tf z_tp";
export var Title3Large = "s_r5 z_r5 z_tf z_tq";