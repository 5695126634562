// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "v_sj d_gv d_cs";
export var heroHeaderCenter = "v_gw d_gw d_cs d_dw";
export var heroHeaderRight = "v_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "v_sk d_gr d_cw";
export var heroParagraphCenter = "v_gs d_gs d_cw d_dw";
export var heroParagraphRight = "v_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "v_sl d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "v_sm d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "v_sn d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "v_sp d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "v_sq d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "v_sr z_sr";
export var heroExceptionNormal = "v_ss z_ss";
export var heroExceptionLarge = "v_st z_st";
export var Title1Small = "v_sv z_sv z_tf z_tg";
export var Title1Normal = "v_sw z_sw z_tf z_th";
export var Title1Large = "v_sx z_sx z_tf z_tj";
export var BodySmall = "v_sy z_sy z_tf z_ty";
export var BodyNormal = "v_sz z_sz z_tf z_tz";
export var BodyLarge = "v_sB z_sB z_tf z_tB";