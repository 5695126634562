// extracted by mini-css-extract-plugin
export var socialContainer = "y_s1";
export var instagramLink = "y_s2";
export var socialWrapperLeft = "y_qz d_dC d_Z d_bz d_bJ d_bp d_bG";
export var socialContentContainer = "y_s3";
export var socialContentContainerFull = "y_s4";
export var instagramContainer = "y_s5";
export var twitterContainer = "y_s6";
export var facebookContainer = "y_s7";
export var socialErrorContainer = "y_s8";
export var facebookContainerWide = "y_s9";
export var twitterContainerWide = "y_tb";
export var socialParagraphCenter = "y_tc";
export var instaWrapper = "y_td";
export var SubtitleSmall = "y_pd z_pd z_tf z_tr";
export var SubtitleNormal = "y_pf z_pf z_tf z_ts";
export var SubtitleLarge = "y_pg z_pg z_tf z_tt";
export var textLeft = "y_dv";
export var textCenter = "y_dw";
export var textRight = "y_dx";