// extracted by mini-css-extract-plugin
export var alignLeft = "w_rd d_bG";
export var alignCenter = "w_bP d_bD";
export var alignRight = "w_rf d_bH";
export var textAlignLeft = "w_sC";
export var textAlignCenter = "w_sD";
export var textAlignRight = "w_sF";
export var hoursInnerWrapperAlt = "w_sG d_fb d_P d_Z d_bz d_bD d_bP d_dj";
export var hoursInnerWrapperLeft = "w_sH d_fc d_fb d_P d_Z d_bz d_bD d_bP d_b5 d_dj";
export var hoursText = "w_sJ d_dw";
export var titleMargin = "w_sK d_cw";
export var hoursInnerInnerWrapper = "w_sL d_cz";